<template>
  <el-card shadow="never" class="login-table">
        <el-table
          v-loading="logLoading"
          :data="loginData"
          stripe
          :header-cell-style="{
            'text-align': 'center',
            background: '#F6F7FA',
            color: '#333',
          }"
          :cell-style="{ 'text-align': 'center' }"
          style="
            width: 100%;
            text-align: center;
            border: 1px solid #e4e6ec;
            border-bottom: none;
          "
          
        >
        <!-- :row-class-name="tableLogClassName" -->
        <el-table-column
          label="序号"
          :index="indexMethod"
          type="index"
          width="80"
          fixed
        ></el-table-column>
        <el-table-column prop="orgName" label="机构名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="saleName" label="机构销售" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="submitTime" label="提交时间" show-overflow-tooltip> </el-table-column>
          <el-table-column label="审核状态">
          <template slot-scope="scope">
            <el-link type="danger" :underline="false" v-if="scope.row.examineStatus == 0">待审核</el-link>
            <el-link type="success" :underline="false" v-else>已审核</el-link>
            </template>
            </el-table-column>
            <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link type="primary" @click="goDetail(scope.row)">详情</el-link>
            </template>
            </el-table-column> 
        </el-table>
        <Pagination
          :pageNumber="page.pageNumber"
          :pageSize="page.pageSize"
          :total="total"
          @SizeChange="SizeChange"
          @CurrentChange="CurrentChange"
        ></Pagination>
      </el-card>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import {list} from '@/api/Thesaurus' 
export default {
    components:{
        Pagination
    },
data() {
    return {
        page: {
        pageNumber: 1,
        pageSize: 10,
      }, //分页数据
        total:0,
        logLoading:false,
        loginData:[],
    }
},
mounted() {
    this.getInfo() 
},
methods:{
    goDetail(row={}) {
      let routeUrl = this.$router.resolve({
        path: "/settings/cwbsckDetail",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: row?.id,
            })
          ),
        },
      });
      window.open(routeUrl.href, "_blank");
      //   this.$router.push({
      //       path:'/settings/cwbsckDetail'
      //   })
    },
     // 表格序号
    indexMethod(index) {
      return index + this.page.pageNumber * this.page.pageSize - this.page.pageSize + 1;
    },
    async getInfo() {
        this.logLoading = true
        let data = {
          page: this.page.pageNumber,
          limit: this.page.pageSize,
        }
        let Res =  await list(data) 
        this.loginData = Res?.data?.data?.records || []
        this.total = Res?.data?.data?.total / 1;
        this.logLoading = false
    },
    SizeChange(val) {
      this.page.pageSize = val;
      this.getInfo()
    },
    CurrentChange(val) {
      this.page.pageNumber = val;
      this.getInfo()
    }
 }
}
</script>

<style>

</style>