<template>
  <div>
    <!-- 标题栏 -->
    <TitleCard>
      <template v-slot:title>
        <Title>平台以及账号数量基本情况 </Title>
      </template>
    </TitleCard>
    <!--  内容栏  -->
    <el-card shadow="never" style="height: 290px; z-index: 1" v-loading="specialActionsLoading">
      <!-- 多个 -->
      <el-carousel v-if="maxlength > 2" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item,index) in platformType" :key="index">
          <div class="swiper">
            <div class="swiper-item" v-for="(item2) in item" :key="item2.id">
              <div style="width:80px;height:80px">
                <img :src="item2.imgUrl" style="width:60px" alt="" srcset="">
              </div>
              <!-- <div class="color">{{ myInfo.orgName === '新疆乌鲁木齐市网信办' && item2.label === '微信公众号' ? 217927 : item2.num }}</div> -->
              <div class="color">{{ item2.num }}</div>
              <div>{{ item2.label }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- 单个和两个 -->
      <div class="box-1" v-else>
        <div class="block-1" v-for="(item, index) in platformType" :key="index">
          <div class="block-1-l">
            <img :src="item.imgUrl" style="width:60px" alt="" />
          </div>

          <div class="block-1-r">
            <div class="block-1-r-t">{{ item.label }}</div>
            <div class="block-1-r-b">{{ item.num }}</div>
          </div>
        </div>
      </div>

      <!-- <div class="box">
        <div class="block" v-for="(item, index) in platformType" :key="index">
          <div class="block-left">
            <img :src="item.imgUrl" width="18px" />
            <span style="margin-left: 6px">{{ "属地" + item.label }}</span>
          </div>
          <div class="block-right">{{ item.num || 0 }}</div>
        </div>
      </div> -->
    </el-card>
  </div>
</template>

<script>
import Title from "../../../base/Title";
import Index from "@/api/index/index";
import Error from "@/api/error/index";
import TitleCard from "../../../components/TitleCard";
import ButtonSelection from "../../../components/ButtonSelection";
export default {
  components: { TitleCard, Title, ButtonSelection },
  data() {
    return {
      specialActionsLoading: true, //加载效果
      // 平台类型
      platformType: [],
      maxlength: 0,
      // myInfo: JSON.parse(window.localStorage.getItem("user"))
    };
  },
  methods: {
    // 获取平台账号数量
    async getPlatformAccountNum() {
      const res = await Index.getPlatformAccountNum();
      if (res.data.code == 200) {
        console.log('accountStat', res.data.data);
        // this.platformType.forEach((y) => {
        //   res.data.data.forEach((x) => {
        //     if (x.name.trim() == y.label.trim()) {
        //       y.num = x.value.trim();
        //     }
        //   });
        // });
        this.platformType = res.data.data.map(v=>{
          return {
            label:v.name,
            num:v.value,
            imgUrl:require("@/assets/img/BigPingTai/" +
            v.type +
            ".png")
          }
        })
        this.maxlength = this.platformType.length
        if (this.platformType.length > 2) {
          let list = []
          for (let i = 0; i < this.platformType.length; i += 4) {
            list.push(this.platformType.slice(i, i + 4));
          }
          this.platformType = list
        }
        console.log(this.platformType);
        this.specialActionsLoading = false;
        this.$forceUpdate();
      }
    },
    // 获取平台类型
    async getPlatformList() {
      this.specialActionsLoading = true;
      const res = await Error.getPlatformList();
      if (res.data.code == 200) {
        this.platformType = res.data.data;
        // if (this.platformType.length > 20) {}
        // this.platformType = this.platformType.slice(0, );
        this.platformType.forEach((x) => {
          x.imgUrl = require("@/assets/img/BigPingTai/" +
            x.value +
            ".png");
        });
        console.log(this.platformType);
        // this.specialActionsLoading = false;
        this.getPlatformAccountNum();
      }
    },
  },
  mounted() {
    this.getPlatformList();
  },
};
</script> 

<style lang="scss" scoped>
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}
.swiper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 50px;
  .swiper-item {
    flex: 1;
    height: 150px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    .color {
      color: #ed731f;
      font-weight: bolder;
    }
  }
}
.box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .block {
    flex-grow: 1;
    flex-basis: 25%;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
    color: #333746;
    padding: 4px 32px 30px 0;
    .block-left {
      display: flex;
      align-items: center;
    }
    .block-right {
      color: #fc9b0a;
    }
  }
}
.box-1 {
  width: 100%;
  height: 260px;
  // display: flex;
  background-image: url("../../../assets/img/gjh/ptbj1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-start;
  .block-1 {
    width: 50%;
    height: 100%;
    display: flex;
    padding-top: 70px;
    padding-left: 30px;
    .block-1-l {
      width: 100px;
      height: 100px;
      background: #ffffff;
      padding: 25px;
      border-radius: 10px;
      display: flex;
      // align-items: center;
      // text-align: center;
      img {
        margin: 0 auto;
        width: 120px;
      }
    }
    .block-1-r {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 15px;
      font-size: 18px;
      font-weight: 800;
      .block-1-r-t {
        color: black;
        padding: 10px;
      }
      .block-1-r-b {
        color: #ffffff;
        padding: 10px;
      }
    }
  }
}
</style>